import Heading1 from "@/lib/Layout/Wrappers/Heading1";
import Paragraph from "@/lib/Layout/Wrappers/Paragraph";
import Section from "@/lib/Layout/Wrappers/Section";
import React from "react";
import { BsFillEyeSlashFill, BsFlagFill } from "react-icons/bs";
import { SiFsecure } from "react-icons/si";
import { BiSupport } from "react-icons/bi";
import { motion } from "framer-motion";
import Image from "next/image";

type Window = {
  width?: number;
  height?: number;
};

function SafeHands() {
  const [windowSize, setWindowSize] = React.useState<Window>({
    width: undefined,
    height: undefined,
  });

  React.useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window?.innerWidth,
        height: window?.innerHeight,
      });
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const safeHandsData = [
    {
      id: 1,
      title: "Data Enabled",
      image: (
        <Image
          src="https://assets.bonatra.com/package_detail/database.webp"
          alt="database"
          width="40"
          height="40"
        />
      ),
      description:
        "Bonatra leverages data-driven innovation to seamlessly merge insights and technology, driving exceptional value and optimal outcomes.",
    },
    {
      id: 2,
      title: "Empowering",
      image: (
        <Image
          src="https://assets.bonatra.com/package_detail/empowerment%20(1).webp"
          alt="database"
          width="40"
          height="40"
        />
      ),
      description:
        "Bonatra empowers users with essential insights for informed decisions, nurturing a path to a healthier, fulfilling life.",
    },
    {
      id: 3,
      title: "Trusted",
      image: (
        <Image
          src="https://assets.bonatra.com/package_detail/relationship.webp"
          alt="database"
          width="40"
          height="40"
        />
      ),
      description:
        "Bonatra's unwavering user trust is built on consistent delivery of high-quality solutions surpassing expectations.",
    },
    {
      id: 4,
      title: "Expertise",
      image: (
        <Image
          src="https://assets.bonatra.com/package_detail/healthcare%201%20(1).webp"
          alt="database"
          width="40"
          height="40"
        />
      ),
      description:
        "Bonatra's team of experienced doctors, dieticians, and healthcare professionals, offer comprehensive and reliable health solutions.",
    },
  ];

  return (
    <Section>
      <h3 className="text-center pt-16 text-6xl font-semibold">{`You're in safe hands`}</h3>
      <Paragraph className="py-8 text-center">
        Thousands of people have trusted Bonatra with their healthcare needs and
        we take pride in providing the best service
      </Paragraph>
      <div className="flex flex-row flex-wrap justify-center item-center md:flex-nowrap gap-4">
        {safeHandsData?.map((item, id) => {
          return (
            <motion.div
              key={id}
              className="w-[12rem] md:w-[17rem] h-[12rem] md:h-64 shadow-md flex flex-col justify-evenly items-center p-2 md:p-8 rounded-lg"
              whileInView={{ y: [50, 0], opacity: [0, 1] }}
              transition={{
                duration: 0.1,
                y: { type: "spring" },
                delay: id * 0.1,
              }}
            >
              <div>{item?.image}</div>
              <h4 className="font-bold">{item?.title}</h4>
              <Paragraph className="text-center text-sm">
                {item?.description}
              </Paragraph>
            </motion.div>
          );
        })}
      </div>
    </Section>
  );
}

export default SafeHands;
